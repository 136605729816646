import { j as e } from "./jsx-runtime-B6kdoens.js";
import { B as r } from "./Button-ChOUYHf0.js";
import { I as s } from "./Icon-CK-T4e-h.js";
import { S as i } from "./Stack-C1p7gIIp.js";
import { T as n } from "./Text-DyXxCJgw.js";
const m = (t) => /* @__PURE__ */ e.jsxs(i, { row: !0, space: "md", className: "justify-start overflow-hidden bg-white rounded-md", children: [
  t.status === "LOADING" && /* @__PURE__ */ e.jsx(s, { name: "Refresh", width: 20, height: 20, strokeWidth: 1.5, className: "shrink-0 animate-spin text-green-flowkit  [&>_path:nth-child(2)]:hidden" }),
  t.status === "FAILURE" && /* @__PURE__ */ e.jsx(s, { name: "Cancel", width: 20, height: 20, strokeWidth: 1.5, className: "shrink-0  bg-destructive text-destructive-foreground rounded-full" }),
  t.status === "SUCCESS" && /* @__PURE__ */ e.jsx(s, { name: "Check", width: 20, height: 20, strokeWidth: 1.5, className: "shrink-0  bg-green-flowkit text-white rounded-full" }),
  /* @__PURE__ */ e.jsxs(i, { space: "lg", children: [
    /* @__PURE__ */ e.jsxs(i, { space: "sm", children: [
      t.title && /* @__PURE__ */ e.jsx(
        n,
        {
          size: "sm",
          className: "pr-4",
          children: t.title
        }
      ),
      t.children,
      t.hint && /* @__PURE__ */ e.jsx(
        n,
        {
          size: "xs",
          variant: t.status === "FAILURE" ? "destructive" : "secondary",
          className: "flex items-center gap-2",
          children: t.hint
        }
      )
    ] }),
    t.status === "LOADING" && /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(
      r,
      {
        size: "sm",
        onClick: (a) => {
          a.stopPropagation(), a.preventDefault(), t.onCancel();
        },
        variant: "outline",
        type: "button",
        className: "font-normal",
        children: "Cancel"
      }
    ) })
  ] })
] });
export {
  m as default
};
